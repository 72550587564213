import React from "react"

import Button from "components/Button"
import NewsTile from "components/NewsTile"

import { usePosts } from "context/posts"
import { useLocale } from "context/locale"

const PostOther = ({ id }) => {
  const { t, lang } = useLocale()
  const { allPosts, allPostsEN, allPostsUA } = usePosts()

  const default_data =
    lang === "en" ? allPostsEN : lang === "ua" ? allPostsUA : allPosts

  return (
    default_data?.length > 0 && (
      <section className="home-news">
        <div className="container-fluid">
          <div className="home-news__row">
            <h2>{t("Aktualności")}</h2>
            <Button to={t("/aktualnosci/")} className="button--bordered">
              {t("zobacz wszystkie")}
            </Button>
          </div>
          <div className="row">
            {default_data
              ?.filter(item => item.id !== id)
              ?.slice(0, 3)
              .map((item, index) => (
                <div className="col-md-4" key={index}>
                  <NewsTile item={item} className="news-tile--smaller" />
                </div>
              ))}
          </div>
        </div>
      </section>
    )
  )
}

export default PostOther
