import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

import { PostContent, PostOther } from "page_components/news"

const Post = ({ pageContext, location }) => {
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  return (
    <Layout
      location={location}
      seo={{
        title: pageContext?.data?.data?.seo.title
          ? pageContext?.data?.data?.seo.title
          : pageContext?.data?.title,
        description:
          pageContext?.data?.data?.seo.metaDesc &&
          pageContext?.data?.data?.seo.metaDesc,
      }}
      rmLangSwitcher
    >
      <Breadcrumbs
        parent={
          location?.pathname?.includes("/en/news/")
            ? {
                label: "News",
                url: "/en/news",
              }
            : { label: "Aktualności", url: "/aktualnosci" }
        }
        title={pageContext?.data?.title}
      />
      <PostContent data={pageContext?.data} />
      <PostOther id={pageContext?.data?.id} />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Post
