import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import { useLocale } from "context/locale"

import { getInvestmentName } from "utils/functions"

import ArrowTile from "components/Icons/arrow"

const NewsTile = ({ item, className }) => {
  const { t } = useLocale()

  return (
    <Link
      to={`${t("/aktualnosci")}/${item.slug}/`}
      className={`news-tile${className ? ` ${className}` : ""}`}
    >
      <div className="news-tile__wrapper">
        <div
          className="news-tile__image"
          style={{
            backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
          }}
        />
        <div className="news-tile__label">
          {t(
            getInvestmentName(item?.acfPost?.investmentRelation) !== null
              ? getInvestmentName(item?.acfPost?.investmentRelation)
              : item?.categories?.nodes[0]?.name
          )}
        </div>
      </div>
      <p className="news-tile__date">
        {item?.date.split("T")[0].split("-").reverse().join("-")}
      </p>
      <h3>{item?.title}</h3>
      <ArrowTile />
    </Link>
  )
}

export default NewsTile
