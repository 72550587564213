import "./styles.scss"

import React from "react"

import NewsTile from "components/NewsTile"
import NewsTileRow from "components/NewsTileRow"

import { usePosts } from "context/posts"
import { useLocale } from "context/locale"

const Content = ({ cat }) => {
  const { t, lang } = useLocale()
  const { allPosts, allPostsEN, allPostsUA } = usePosts()

  const default_data =
    lang === "en" ? allPostsEN : lang === "ua" ? allPostsUA : allPosts
  const filteredData = cat
    ? default_data?.filter(item => item.categories.nodes[0].name === cat)
    : default_data

  return (
    <section className="news-content">
      <div className="container-fluid">
        {filteredData?.slice(0, 1).map((item, index) => (
          <NewsTileRow item={item} key={index} />
        ))}
        <div className="row">
          {filteredData?.slice(1).map((item, index) => (
            <div className="col-md-6" key={index}>
              <NewsTile item={item} />
            </div>
          ))}
        </div>
        {!filteredData.length > 0 && (
          <p className="news-content__info">
            {t("Brak aktualności do wyświetlenia.")}
          </p>
        )}
        <p></p>
      </div>
    </section>
  )
}

export default Content
